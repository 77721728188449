import logo from './logo.svg';
import './App.css';
import giLogo from './assets/img/logo.png'
import axios from 'axios';
// import { useEffect } from 'react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableSkeletonLoader from './TableSkeletonLoader';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Test from './test'

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossorigin="anonymous" referrerpolicy="no-referrer" />

function App() {
  const [data, setData] = useState([]);
  const [apiBaseURL, setApiBaseUrl] = useState("https://api.budgets.gisolutions.co.nz:4476/")
  const [selectedYear, setSelectedYear] = useState("");
  // const [defaultYear,setDefaultYear]=useState("");
  const [yearstart, setYearStart] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [montharr, setmontharr] = useState({
    "customers": [],
    "total": [],
  })

  useEffect(() => {
    setLoading(true);
    const status = localStorage.getItem('login');
    const gettoken = localStorage.getItem('token');
    if (status === 'yes') {
      async function fetchData() {
        try {
          const axios = require('axios');
          let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${apiBaseURL}getsub`,
            headers: {
              'Content-Type': 'application/json'
            }
          };

          axios.request(config)
            .then((response) => {
              setData(response.data.data);
              setLoading(false);
            })
            .catch((error) => {
              return error;
            });



        } catch (error) {
          // console.error(error);
          setLoading(false);
        }
      } fetchData();
    } else {
      navigate('/')
    }
  }, []);
  // console.log(`data : ${data}`);


  // Set the default selected year to the current year + 1
  useEffect(() => {
    // $(function () {
    //   $('[data-toggle="tooltip"]').tooltip()
    // })

    const currentYear = new Date().getFullYear();
    const defaultYear = currentYear + 1;

    setSelectedYear(defaultYear.toString());
    setYearStart(currentYear.toString());

    // Load data for the default year
    const loadDefaultYearData = async () => {
      try {
        const gettoken = localStorage.getItem('token');
        const response = await axios.get(`${apiBaseURL}getsub`);

        setData(response.data.data);
        const newArr = convertData(Object.entries(response.data.data));
        // console.log("test",Object.entries(response.data.data))
        setmontharr(newArr);

      } catch (error) {
        console.error(error);

      }
    };

    loadDefaultYearData();

    handleYearSelect({ target: { value: defaultYear.toString() } });
  }, []);

  //>>> onClange function

  const handleYearSelect = (event) => {
    setLoading(true);
    const selectedYear = Number(event.target.value);
    const orderedMonths = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
    // const subscriptions = [];
    setSelectedYear(selectedYear);
    setYearStart(selectedYear - 1)
    // console.log(`Selected year: ${selectedYear}`);
    const gettoken = localStorage.getItem('token');

    // setLoading(true)
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${apiBaseURL}getsub`,
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const result = {};
      axios.request(config)
        .then((response) => {
          // console.log("selected", response.data.data)
          // console.log(response.data.data);
          setData(response.data.data);
          const loop = 0;
          var months = [];
          var planes = [];
          var unitCost = [];
          var unitQty = [];
          response.data.data.forEach(subscription => {
            subscription.plans.forEach(plansData => {
              const subscriptionYear = new Date(subscription.start_date).getFullYear();
              const subscriptionMonth = new Date(subscription.start_date).getMonth() + 1; // Month is zero-based

              // console.log(`subscriptionYear: ${subscriptionYear},subscriptionMonth: ${subscriptionMonth}`)

              // Check if the subscription is for the selected year or the next year
              // console.log(`1: ${subscriptionYear}`)
              if ((subscriptionYear >= selectedYear - 1 && subscriptionYear <= selectedYear) || (subscriptionYear <= selectedYear - 1) || (subscriptionYear >= selectedYear - 1)) {
                // console.log(`2: ${subscriptionYear}`)
                const customerName = subscription.party;
                const status = subscription.status;
                // console.log(`customerName: ${customerName}`)
                // console.log(`status : ${status}`)
                // Create the customer's entry if it doesn't exist
                const index = 0;

                if (!result[customerName] && !(status == 'cancelled')) {
                  months = [];
                  planes = [];
                  unitCost = [];
                  unitQty = [];
                  result[customerName] = {};
                  // index++;
                  //console.log(`result 1: ${result}`)
                }
                // Loop through months starting from April of the current year

                const arr = [];
                const plansarr = [];
                const cost = [];
                const qty = [];


                for (let year = selectedYear - 1; year <= selectedYear; year++) {
                  const startMonth = year == selectedYear - 1 ? 4 : 1; // Start from April or January
                  const endMonth = year == selectedYear ? 3 : 12; // Up to March or December
                  // console.log(`year: ${year}`)
                  //  console.log(`startMonth: ${startMonth},endMonth: ${endMonth}`)

                  for (let month = startMonth; month <= endMonth; month++) {
                    const monthName = getMonthName(month);
                    // console.log(`monthName : ${monthName}`)
                    //const key = `${monthName}`;
                    // Set the amount to 0 for months before the subscription month within the same year
                    // console.log('plans', plansData.details.billing_interval)

                    if ((year == subscriptionYear && month < subscriptionMonth) ||
                      (year < subscriptionYear)) {
                      arr.push((0).toFixed(2));
                      plansarr.push("NA");
                      cost.push((0).toFixed(2));
                      qty.push(plansData.qty);

                      // index++;
                      //  console.log(`if result: ${arr}`)
                    }

                    else if (plansData.details.billing_interval == 'Year') {
                      console.log("years in ")
                      if ((subscriptionMonth == month)) {
                        // console.log("cost",plansData.details.cost.toFixed(2))
                        // console.log("plan_name",plansData.details.plan_name)
                        console.log('quantity', plansData.qty)
                        if (plansData.qty > 1) {
                          arr.push(plansData.details.cost.toFixed(2) * plansData.qty);
                          plansarr.push(plansData.details.plan_name);
                          cost.push(plansData.details.cost.toFixed(2));
                          qty.push(plansData.qty);
                        }
                        else {
                          arr.push(plansData.details.cost.toFixed(2));
                          plansarr.push(plansData.details.plan_name)
                          cost.push(plansData.details.cost.toFixed(2));
                          qty.push(plansData.qty);
                        }
                        // console.log(`subscriptionMonth : ${arr}`)
                      }
                      else {
                        // console.log("month else")
                        arr.push((0).toFixed(2));
                        plansarr.push("NA");
                        cost.push((0).toFixed(2));
                        qty.push(plansData.qty);
                      }
                      // console.log(subscription.interval_unit)
                    }
                    else {
                      console.log("month in ")
                      if (plansData.qty > 1) {
                        arr.push(plansData.details.cost.toFixed(2) * plansData.qty);
                        plansarr.push(plansData.details.plan_name);
                        cost.push(plansData.details.cost.toFixed(2));
                        qty.push(plansData.qty);
                      }
                      else {
                        arr.push(plansData.details.cost.toFixed(2));
                        plansarr.push(plansData.details.plan_name);
                        cost.push(plansData.details.cost.toFixed(2));
                        qty.push(plansData.qty);
                      }
                      // console.log(`Planes : ${planes}`)
                      // index++;
                    }
                  }
                }
                // console.log(`array : ${arr}`)
                months.push(arr)
                planes.push(plansarr)
                unitCost.push(cost)
                unitQty.push(qty)
                // console.log(`planes : ${planes}`)
                // console.log(`months : ${months}`)
                // console.log(`unitCost:${unitCost}`)
                // console.log(`unitQty:${unitQty}`)
                result[customerName]["months"] = months
                result[customerName]["plancode"] = planes
                result[customerName]["unitCost"] = unitCost
                result[customerName]["unitQty"] = unitQty


                //  console.log(`Result : ${result}`)
              }
              // loop++;
              //console.log(`new array ${result}`)
            })
          });
          // console.log("1")
          // console.log(`new array ${(result)}`)
          console.log("result Object")
          console.log(Object.entries(result))

          var newArr = convertData(Object.entries(result));
          // console.log(newArr)
          setmontharr(newArr)
          setLoading(false);
        })
        .catch((error) => {
          return error;
        });


    } catch (error) {
      // console.error(error);
      setLoading(false);
    }

    function getMonthName(monthNumber) {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      return months[monthNumber - 1];
    }

    // setLoading(false)
  };

  function sumAllValues(arrays) {
    if (arrays.length === 0) {
      throw new Error("No arrays provided");
    }

    const numMonths = arrays[0].length; // Assuming all arrays have the same length

    const sums = new Array(numMonths).fill(0);

    for (let i = 0; i < arrays.length; i++) {
      const currentArray = arrays[i];
      for (let j = 0; j < numMonths; j++) {
        sums[j] += parseFloat(currentArray[j]);
      }
    }
    return sums.map(sum => sum.toFixed(2));
    // return sums.map((sum, index) => (
    //   <td key={index}>{sum.toFixed(2)}</td>
    // ));
  }

  function convertData(months) {
    // console.log('months',months)
    var newArr = [];
    var customers = [];
    var customersDetails = [];
    var total = new Array(12).fill(parseFloat(0));
    var customerTotal = [];
    var sumTotal = 0;
    // Iterate through the "months" array
    months.map((result) => {
      // Calculate the sum of values in the "customers" index for each array
      const monthSum = sumAllValues(result[1]['months']);
      customers.push(monthSum);
      customersDetails.push({
        "name": result[0],
        "amounts": monthSum,
        "plan_code": result[1]['plancode'],
        "subsriptions": result[1]['months'],
        "unitCost": result[1]['unitCost'],
        "unitQty": result[1]['unitQty'],
      })
    });

    for (let i = 0; i < customers.length; i++) {
      // const currentArray = customer[i];
      for (let j = 0; j < 12; j++) {
        // console.log(customers[i][j]);
        total[j] += parseFloat(customers[i][j]);
      }
    }
    total.map((amounts, totalIndex) => {
      sumTotal += amounts;
    }
    )
    // console.log(`customerTotal : ${commenTotal}`);

    for (let i = 0; i < customers.length; i++) {
      let add = 0;
      for (let j = 0; j < 12; j++) {
        // Access the correct values from the customers array
        add += parseFloat(customers[i][j]);
      }
      customerTotal.push(add);
    }
    // console.log(`CustomerTotal : ${customerTotal}`);
    newArr['customers'] = customersDetails;
    newArr['total'] = total;
    newArr['customerTotal'] = customerTotal;
    newArr['sumTotal'] = sumTotal;

    return newArr;
  }

  function getBreakdown(plans, amount, cost, qty, index) {
    let message = '';
    plans.map((plan, planIndex) => {
      if (plan[index] !== 'NA') {
        if (qty[planIndex][index] > 1) {
          message += `${plan[index]} : $${cost[planIndex][index]} * ${qty[planIndex][index]} = ${parseFloat(amount[planIndex][index]).toFixed(2)}\n`;
        } else {
          message += `${plan[index]} : $${amount[planIndex][index]}\n`;
        }
      }
    });
    return message.trim();
  }



  function logout() {
    localStorage.clear();
    navigate('/');
  }

  // useEffect
  //   (() => {
  //       // Initialize Bootstrap tooltips
  //       const bootstrap=require('bootstrap');
  //       const tooltips = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]')); tooltips.map((tooltip) =>new bootstrap.Tooltip(tooltip));
  //     }, []);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - 4 + index);

  return (
    <>
      <Helmet>
        <title>GI Budgets | Application</title>
      </Helmet>
      <div className="App mt-3">
        <div className="container-fluid">
          <div className='row mb-3'>
            <div className='col-md-4 d-flex'>
              <img src={giLogo} className='logo'></img>
            </div>
            <div className='col-md-4 d-flex justify-content-center align-self-center form-group'>
              <div className="form-group">
                <select id="yearSelect" className="form-control" onChange={handleYearSelect}>
                  <option> FY :{selectedYear} </option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      FY : {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-4 d-flex justify-content-end align-self-center p-0'>
              <i class="fa-solid fa-power-off fs-2 logout" onClick={() => logout()}></i>
            </div>
          </div>
          {console.log('motharry', montharr)}
          {
            loading
              ? (
                <TableSkeletonLoader />
              )
              : (<div className="row table-container">
                <table className="table table-hover table-bordered fs-13">
                  <thead className="table-active">
                    <tr>
                      <th scope="col" className="heading text-color text-center align-middle" rowSpan={2}>S.No</th>
                      <th scope="col" className="heading text-color text-center align-middle" rowSpan={2}>Customers</th>
                      <th colSpan={9}>{yearstart}</th>
                      <th colSpan={3}>{selectedYear}</th>
                      <th scope="col" className="text-color align-middle" rowSpan={2}>Customer Total</th>
                    </tr>
                    <tr className="table-active">
                      <th scope="col" className='text-color'>Apr</th>
                      <th scope="col" className='text-color'>May</th>
                      <th scope="col" className='text-color'>Jun</th>
                      <th scope="col" className='text-color'>Jul</th>
                      <th scope="col" className='text-color'>Aug</th>
                      <th scope="col" className='text-color'>Sep</th>
                      <th scope="col" className='text-color'>Oct</th>
                      <th scope="col" className='text-color'>Nov</th>
                      <th scope="col" className='text-color'>Dec</th>
                      <th scope="col" className='text-color'>Jan</th>
                      <th scope="col" className='text-color'>Feb</th>
                      <th scope="col" className='text-color'>Mar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      montharr.customers.map((customer, customerIndex) =>
                        <tr key={customerIndex}>
                          <th scope="row">{customerIndex + 1}</th>
                          <th scope="row" className='bg-color'>{customer.name}</th>
                          {customer.amounts.map((amount, monthIndex) => (
                            <td key={monthIndex} data-toggle="tooltip" data-placement="right"
                              data-html="true" title={getBreakdown(customer.plan_code, customer.subsriptions, customer.unitCost, customer.unitQty, monthIndex)} className="amount">{`${amount == '0.00' ? '-' : '$' + amount}`}</td>
                          ))
                          }
                          <td scope="row" className='text-color fw-bolder'>{`$ ${parseFloat(montharr.customerTotal[customerIndex]).toFixed(2)}`}</td>
                        </tr>
                      )
                    }


                    <tr className="table-active">
                      <th scope="col" className='heading'></th>
                      <th scope="col" className='heading'>Month Total</th>
                      {
                        montharr.total.map((totalAmount, monthIndex) => (
                          <th scope="col" className='text-color' key={monthIndex}>{`$${parseFloat(totalAmount).toFixed(2)}`}</th>
                        ))
                      }
                      {
                        typeof montharr.sumTotal === 'number' ? (
                          <th scope="col" className='text-color'>{`$ ${parseFloat(montharr.sumTotal).toFixed(2)}`}</th>
                        ) : (
                          <></>
                        )
                      }

                    </tr>
                  </tbody>
                </table>
              </div>)
          }
        </div>
      </div>
    </>

    // <Test></Test>
  );
}

export default App;
